import workplaceApi from "@/api/workplace.service.js";

export default {
  async initialize({ state, commit }) {
    // Create a new controller to cancel concurrent requests
    commit("setFilterController", new AbortController());

    // Get available products
    const { data } = await workplaceApi.fetchCustomerWorkplaceData(
      state.locale,
      state.meta.filterController.signal,
    );

    commit("setAvailableUsers", data.users);
  },

  async getFilter({ state, commit }, { filterId }) {
    // Create a new controller to cancel concurrent requests
    commit("setFilterController", new AbortController());

    // Get filter
    const { data } = await workplaceApi.fetchFilter(
      filterId,
      state.meta.filterController.signal,
    );

    commit("setId", data.id);
    commit("setLocale", data.localeCode);
    commit("setZipCode", data.zipCode);
    commit("setName", data.name);
    commit("setRadius", data.radius);
    commit("setSlotsAvailable", data.slotsAvailable);
    commit("setWithDescription", data.withDescription);
    commit("setProducts", data.products);
    commit("setTextSearchTags", data.textSearchTags);

    commit("setNotificationFrequency", data.frequency);
    commit("setNotificationFrequencyDay", data.frequencyDay);
    commit("setNotificationFrequencyHour", data.frequencyHour);
    commit("setNotificationFrequencyWeekend", data.frequencyWeekend);
    commit("setIsFavorite", data.isFavorite);
    commit("setUsers", data.users);

    commit(
      "setContact",
      Object.entries({
        spoken: data.spoken,
      })
        .filter(([, value]) => value)
        .map(([key]) => key),
    );
  },
  async saveFilter({ commit, state }) {
    // Create a new controller to cancel concurrent requests
    commit("setFilterController", new AbortController());

    // Get filter
    await workplaceApi.saveFilter(
      state.filter,
      state.meta.filterController.signal,
    );
  },
};
