import { createGtmEvent } from "@solvari/common-fe/argus/thick-client";

import { useToast } from "@solvari/components";
import { useI18nCustomer } from "@solvari/translations";
import { debounce, getEnv } from "@solvari/utils";

import workplaceApi from "@/api/workplace.service.js";
import { workplaceEvents } from "@/enums/events.enum";

export default {
  async initialize({ commit, dispatch }, { localeCode, filterId }) {
    commit("setLocale", localeCode || getEnv().config.localeCode);
    // Fetch required data
    await Promise.all([
      dispatch("dispatchCustomerWorkplaceData"),
      dispatch("dispatchSavedFilters"),
    ]);
    // Prepare filter
    if (filterId) {
      await dispatch("switchToFilterById", parseInt(filterId));
    } else {
      await dispatch("dispatchFilters");
    }
    commit("setFilterLoaded", true);
    // Fetch results
    await dispatch("dispatchResults");
  },
  async dispatchCustomerWorkplaceData({ getters, commit }) {
    const { data } = await workplaceApi.fetchCustomerWorkplaceData(
      getters.getLocale,
    );

    commit("setAllowWorkplaceSale", data.allowWorkplaceSale);
    commit("setIsImpersonatingAdmin", data.isImpersonatingAdmin);
    commit("setAccountManager", data.accountManager);
  },
  async dispatchSavedFilters({ getters, commit }) {
    const { data } = await workplaceApi.fetchSavedFilter(getters.getLocale);

    commit("setSavedFilters", data);
  },
  async dispatchFavoriteFilter({ commit, getters }) {
    commit("setIsFavorite", true);
    await workplaceApi.favoriteFilter(getters.getSelectedFilter);
  },
  async dispatchFilters({ commit, getters }) {
    const { data } = await workplaceApi.fetchFilters(getters.getLocale);

    commit("setZipCode", data.filters.zipCode);
    commit("setProducts", data.filters.products);
    commit("setTextSearchTags", data.filters.textSearchTags);
    commit("setIsFavorite", data.filters.isFavorite);
    commit("setRadius", data.filters.radius);
    commit("setSlotsAvailable", data.filters.slotsAvailable);
    commit("setIsFavorite", data.filters.isFavorite);
    commit(
      "setContact",
      Object.entries({
        Spoken: data.filters.spoken,
      })
        .filter(([, value]) => value)
        .map(([key]) => key),
    );
    // Select the filter by default if the filter is favorite
    if (data.filters.isFavorite) {
      commit("setSelectedFilter", data.filters.id);
    }
  },
  dispatchResults: debounce(async ({ getters, commit, state }) => {
    commit("setLoading", true);
    commit("setLeads", []);

    if (state.meta.filterController) {
      // Cancel previous request
      state.meta.filterController.abort();
    }

    // Create a new controller to cancel concurrent requests
    commit("setFilterController", new AbortController());

    const { data } = await workplaceApi.fetchResults(
      getters.getFilters,
      state.meta.filterController.signal,
    );

    commit("setLeads", data.data);
    commit("setCurrentPage", data.current_page);
    commit("setLastPage", data.last_page);
    commit("setTotalLeads", data.total);
    commit("setLoading", false);
  }, 200),
  async dispatchNextResults({ getters, commit }) {
    commit("setLoading", true);

    const { data } = await workplaceApi.fetchResults({
      ...getters.getFilters,
      page: getters.getCurrentPage + 1,
    });
    commit("setCurrentPage", data.current_page);
    commit("setLeads", [...getters.getLeads, ...data.data]);
    commit("setLoading", false);
  },
  async buyLead({ commit }, buyLeadId) {
    const toastService = useToast();
    try {
      await workplaceApi.buyLead(buyLeadId);

      commit("boughtLead", {
        id: buyLeadId,
      });

      toastService.add({
        summary: useI18nCustomer().tr("customer.workplace.lead_bought"),
        severity: "success",
      });
      void createGtmEvent(workplaceEvents.boughtLead);
    } catch (error) {
      commit("setBuyError", {
        ...error.response.data,
      });

      toastService.add({
        summary: useI18nCustomer().tr("customer.workplace.lead_buy_failed"),
        severity: "error",
      });

      void createGtmEvent(workplaceEvents.boughtLead, {
        value: error.response.data.message,
      });
    }
  },
  async switchLocale({ commit, dispatch }, locale) {
    commit("setLocale", locale);
    commit("setLoading", true);
    commit("setLeads", []);

    dispatch("resetFilter");

    await Promise.all([
      dispatch("dispatchCustomerWorkplaceData"),
      dispatch("dispatchSavedFilters"),
    ]);

    await dispatch("dispatchFilters");
  },
  switchToFilterById({ commit, getters }, id) {
    const filter = getters.getSavedFilterWithId(id);

    if (!filter) {
      return;
    }

    commit("setSelectedFilter", id);
    commit("setRadius", filter.radius);
    commit("setProducts", filter.products);
    commit("setTextSearchTags", filter.textSearchTags);
    commit("setZipCode", filter.zipCode);
    commit("setSlotsAvailable", filter.slotsAvailable);
    commit("setIsFavorite", filter.isFavorite);
    commit(
      "setContact",
      Object.entries({
        Spoken: filter.spoken,
      })
        .filter(([, value]) => value)
        .map(([key]) => key),
    );
  },
  resetFilter({ commit }) {
    commit("setZipCode", "");
    commit("setRadius", null);
    commit("setContact", []);
    commit("setSelectedFilter", null);
    commit("setIsFavorite", null);
    commit("workplace/setTextSearchTags", []);
    commit("setProducts", []);
  },
};
