import type { VueIslandConfig } from "@solvari/common-fe/helpers";
import type { Plugin } from "vue";

import { initAxiosInterceptors } from "@solvari/common-fe/composables";
import { initVueIslands } from "@solvari/common-fe/helpers";
import { initFontAwesome } from "@solvari/common-fe/integrations";
import { createPinia } from "pinia";

import { initPrimeVueThemed, sModalPlugin } from "@solvari/components";
import { useI18nCustomer } from "@solvari/translations";
import { getEnv } from "@solvari/utils";

import { leadDetails } from "@/pages/leadDetails";
import { store } from "@/store/store.js";

import "@/utils/sentry";
import "@solvari/common-fe/polyfills";

import "@/styling/index.scss";
import "@solvari/components/blade/review/review-widget/reviewScore.css";

function main() {
  initFontAwesome();

  initAxiosInterceptors();

  const pages: VueIslandConfig = {
    productsPage: () => import("@/pages/CustomerProducts.vue"),
    workplace: () => import("@/pages/Workplace.vue"),
    workplaceLead: () => import("@/pages/WorkplaceLead.vue"),
    workplaceFilter: () => import("@/pages/WorkplaceFilter.vue"),
    workplaceFiltersOverview: () =>
      import("@/pages/WorkplaceFiltersOverview.vue"),
    creditsOverview: () => import("@/pages/CreditsOverview.vue"),
    crmApi: () => import("@/pages/CrmApi.vue"),
    createPassword: () => import("@/pages/auth/CreatePassword.vue"),
    userEmailVerification: () =>
      import("@/pages/verification/UserEmailVerification.vue"),
    thankYou: () => import("@/pages/finance/ThankYou.vue"),
    accountProblem: () => import("@/pages/auth/AccountProblem.vue"),
  };

  const components: VueIslandConfig = {
    inbox: () => import("@/components/inboxThread.ts"),
    leadSearch: () => import("@/components/lead-search/LeadSearch.vue"),
    walletBalance: () => import("@/components/wallet/WalletBalance.vue"),
    reviewInvite: () => import("@/components/review-invite/ReviewInvite.vue"),
    proDashboardCards: () =>
      import("@/components/dashboard/cards/DashboardCards.vue"),
    serviceCard: () =>
      import("@/components/dashboard/cards/service/ServiceCard.vue"),
    createCampaignModal: () =>
      import("@/components/modal/CreateCampaignModal.vue"),
    editProject: () => import("@/pages/project/EditProject.vue"),
    onboardingFlow: () =>
      import("@/components/onboarding-flow/OnboardingFlow.vue"),
    sBuyCreditsCard: () =>
      import("@/components/buy-credits-card/SBuyCreditsCard.vue"),
    sGalleriaThumbnail: () => import("@/components/sGalleriaThumbnail.ts"),
  };

  initVueIslands(
    { ...pages, ...components },
    (app) => {
      app.use(createPinia());
      app.use(store() as Plugin);
      app.use(sModalPlugin);
      initPrimeVueThemed(app);

      // TODO remove this i18n call when we use composition api more
      useI18nCustomer();
    },
    getEnv().config.localeCode,
  );

  leadDetails();
}

main();
