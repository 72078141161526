import { computedAsync } from "@vueuse/core";
import axios from "axios";
import ky from "ky";
import { defineStore } from "pinia";
import { computed } from "vue";

import type { LocaleIso, LocaleMorpheus } from "@solvari/translations";
import { getEnv } from "@solvari/utils";

type AuthUser = {
  id: number;
  permissions?: string[];
  pro: {
    locale: LocaleIso | LocaleMorpheus;
    subscription: { permissions: string[] } | null;
    uuid: string;
  } | null;
  roles?: string[];
};

async function fetchUser(accessToken: string): Promise<AuthUser> {
  const url = `${getEnv().network.api}/v3/my/user`;

  const response = await ky
    .get(
      url,
      accessToken
        ? // eslint-disable-next-line @typescript-eslint/naming-convention
          { headers: { Authorization: `Bearer ${accessToken}` } }
        : undefined,
    )
    .json<{ data: AuthUser }>();

  return response.data;
}

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    oauthAccessToken?: string;
  }
}

const useAuthStore = defineStore("auth", () => {
  const accessToken = window.oauthAccessToken ?? "";

  const user = computedAsync(() => fetchUser(accessToken), null);

  const userRoles = computed(() =>
    user.value?.roles ? new Set(user.value.roles) : new Set<string>(),
  );

  return {
    accessToken,
    user,
    userId: computed(() => user.value?.id ?? null),
    customerUuid: computed(() => user.value?.pro?.uuid ?? null),
    locale: computed(() => user.value?.pro?.locale ?? null),
    hasAccessToken: !!accessToken,
    isAny: (roles: string[]) => roles.some((role) => userRoles.value.has(role)),
    isAll: (roles: string[]) =>
      roles.every((role) => userRoles.value.has(role)),
  };
});

function initAxiosInterceptors() {
  // Register the authorization request interceptor;
  axios.interceptors.request.use((request) => {
    // If not an API v3 route, exit early by returning the request.
    if (!request.url || !new URL(request.url).pathname.startsWith("/v3/")) {
      return request;
    }

    const authStore = useAuthStore();
    if (authStore.hasAccessToken) {
      request.headers.Authorization = `Bearer ${authStore.accessToken}`;
    }

    return request;
  });
}

export { initAxiosInterceptors, useAuthStore };
