import { ziggy } from "@solvari/common-fe/integrations";

export default {
  setLoading: (state, payload) => (state.loading = payload),
  setLeads: (state, payload) => (state.leads = payload),

  setCurrentPage: (state, payload) => (state.meta.currentPage = payload),
  setLastPage: (state, payload) => (state.meta.lastPage = payload),

  setAllowSavingFilter: (state, payload) => (state.allowSavingFilter = payload),

  setAllowWorkplaceSale: (state, payload) =>
    (state.allowWorkplaceSale = payload),
  setIsImpersonatingAdmin: (state, payload) =>
    (state.isImpersonatingAdmin = payload),

  setAccountManager: (state, payload) => (state.accountManager = payload),

  setIsFilterOpen: (state, payload) => (state.meta.isFilterOpen = payload),

  setSavedFilters: (state, payload) => (state.savedFilters = payload),

  boughtLead: (state, { id }) => {
    // Key: LeadID, value: RedirectUrl
    state.redirectUrls = {
      ...state.redirectUrls,
      [id]: ziggy("customer.leads.redirect_from_storage", { storageId: id }),
    };
    // Find the index of the lead
    const leadIndex = state.leads.find((lead) => lead.id === id);
    // Update the local availability
    state.leads[leadIndex].totalMatches =
      state.leads[leadIndex].totalMatches + 1;
  },
  setBuyError: (state, payload) => (state.buyError = payload),

  /**
   * Filters
   */
  setFilterLoaded: (state, payload) => (state.filterLoaded = payload),
  setZipCode: (state, payload) => (state.filters.zipCode = payload),
  setRadius: (state, payload) => (state.filters.radius = payload),
  setProducts: (state, payload) => (state.filters.products = payload),
  setTextSearchTags: (state, payload) =>
    (state.filters.textSearchTags = payload),
  setLocale: (state, payload) => {
    state.filters.locale = payload;
    // Should be cleared, as format is different per locale
    state.filters.zipCode = "";
  },
  setOrderBy: (state, payload) => (state.filters.orderBy = payload),
  setContact: (state, payload) => (state.filters.contact = payload),
  setSlotsAvailable: (state, payload) =>
    (state.filters.slotsAvailable = payload),
  setSelectedFilter: (state, payload) => (state.selectedFilter = payload),
  setName: (state, payload) => (state.filters.name = payload),
  setId: (state, payload) => (state.filters.id = payload),
  setIsFavorite: (state, payload) => (state.filters.isFavorite = payload),
  setNotificationFrequency: (state, payload) =>
    (state.filters.notificationFrequency = payload),
  setNotificationFrequencyDay: (state, payload) =>
    (state.filters.notificationFrequencyDay = payload),
  setNotificationFrequencyHour: (state, payload) =>
    (state.filters.notificationFrequencyHour = payload),
  setNotificationFrequencyWeekend: (state, payload) =>
    (state.filters.notificationFrequencyWeekend = payload),

  /**
   * Meta
   */
  setTotalLeads: (state, payload) => (state.meta.totalLeads = payload),
  setFilterController: (state, payload) =>
    (state.meta.filterController = payload),
};
