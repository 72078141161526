import { order } from "@/enums/workplace.enum";

export default {
  loading: true,
  leads: [],
  redirectUrls: {}, // For bought leads
  savedFilters: [],
  isImpersonatingAdmin: false,
  allowWorkplaceSale: false,
  allowSavingFilter: false,
  accountManager: {
    name: "",
    image: "",
    email: "",
    phoneNumber: "",
  },
  buyError: {
    leadId: null,
    status: null,
    message: null,
    countries: [],
    paymentConfigDTO: null,
    creditBundles: [],
    email: undefined,
  },
  meta: {
    currentPage: 1,
    lastPage: null,
    isFilterOpen: false,
    totalLeads: 0,
    filterController: null,
    resultsController: null,
  },
  selectedFilter: null,
  filterLoaded: false,
  filters: {
    id: null,
    name: "",
    zipCode: null,
    orderBy: order.DISTANCE,
    slotsAvailable: 0,
    contact: [],
    products: [],
    textSearchTags: [],
    locale: null,
    radius: 300,
    notificationFrequency: "WEEKLY",
    notificationFrequencyHour: 9,
    notificationFrequencyDay: 1,
    notificationFrequencyWeekend: null,
    isFavorite: false,
  },
};
